import React, {useState, useEffect} from 'react'
import {AiFillEye, AiFillGithub} from 'react-icons/ai'
import {motion} from 'framer-motion'
import {AppWrap, MotionWrap} from '../../wrapper'
import {urlFor, client} from '../../client'
import './Certifications.scss'


const Certifications = () => {
  const [activeFilter, setActiveFilter] = useState('All')
  const [animateCard, setAnimateCard] = useState({y: 0, opacity: 1})
  const [Certifications, setCertifications] = useState([])
  const [filterCertifications, setFilterCertifications] = useState([])


  useEffect(() => {
      const query = '*[_type == "certifications"]'

      client.fetch(query)
      .then((data) => {
        setCertifications(data)
        setFilterCertifications(data)
      })
  }, [])
   
  const handleCertificationsFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === 'All') {
        setFilterCertifications(Certifications);
      } else {
        setFilterCertifications(Certifications.filter((certifications) => Certifications.tags.includes(item)));
      }
    }, 500);
  };
   

  return (
    <>
       <h2 className="head-text">My  <span>Certifications</span></h2>

       <div className=" app__certifications-filter">
       {['CI/CD', 'IaC', 'Kubernetes', 'Docker', 'All'].map((item, index)=> (
          <div 
          key={index}
          onClick={() => handleCertificationsFilter(item)}
          className={`app__wertifications-filter-item app__flex p-text ${activeFilter ===  item ? 'item-active' : ''}`}
          >
           {item} 
          </div>
       ))}

       </div>

       <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__certifications-portfolio"
      >
        {filterCertifications.map((certifications, index) => (
          <div className="app__certifications-item app__flex" key={index}>
            <div
              className="app__certifications-img app__flex"
            >
              <img src={urlFor(certifications.imgUrl)} alt={certifications.name} />

              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                className="app__certifications-hover app__flex"
              >
                <a href={certifications.projectLink} target="_blank" rel="noreferrer">

                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.90] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillEye />
                  </motion.div>
                </a>

                   <a href={certifications.codeLink}  target="_blank" rel="noreferrer">
                    <motion.div
                    whileInView={{scale:[0, 1]}}
                    whileHover={{scale: [1,0.9]}}
                    transition={{duration: 0.25}}
                    className=" app__flex"
                    >
                     <AiFillGithub/>
                    </motion.div>
                   </a>

                 </motion.div>
              </div>
                 
              <div className="app__certifications-content app__flex">
              <h4 className="bold-text">{certifications.title}</h4>
              <p className="p-text" style={{ marginTop: 10 }}>{certifications.description}</p>

              <div className="app__certifications-tag app__flex">
                <p className="p-text">{certifications.tags[0]}</p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Certifications, 'app__certifications'), 
  'certifications',
  "app__primarybg"
)